import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    contentRight,
    contentText,
    contentFlexLeft,
    contentFlexRight,
    contentTextLeft,
    contentFlexLeft2,
    contentFlexRight2,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    secondWrapper,
    buttonNew,
} from './color-type-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface ColorTypeSectionProps {
    withTopPadding?: boolean;
    className?: string;
    isRight: boolean;
    title?: string;
    text?: React.ReactNode;
}

export default function ColorTypeSection({ withTopPadding, className, isRight, title, text }: ColorTypeSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark2'}
            className={`${main} ${className} ${withTopPadding ? topPadding : ''}`}
        >
            {/* <div className={contentWrapper}> */}
            <div className={`${isRight ? contentFlexLeft : contentFlexLeft2}`}>
                &nbsp;
            </div>
            <div className={` ${isRight ? contentFlexRight : contentFlexRight2} ${isRight ? '' : contentTextLeft}`}>
                <div className={`${contentText}`}>
                    <h1>{title}</h1>
                    <div className={contentText}>{text}</div>
                </div>
            </div>
            {/* <div className={`${content} ${isRight ? contentRight : ''}`}>
                    <h1>{title}</h1>
                    <div className={contentText}>{text}</div>
                </div> */}
            {/* </div> */}
        </SectionWrapper>
    );
}
