// extracted by mini-css-extract-plugin
export var aboutBeauties = "beauty-type-module--about-beauties--Y8xn1";
export var beauty1 = "beauty-type-module--beauty-1--2Qcvs";
export var beauty1Right = "beauty-type-module--beauty-1-right--r+sXr";
export var beauty2 = "beauty-type-module--beauty-2--vlJ1U";
export var beauty3 = "beauty-type-module--beauty-3--BfJ4O";
export var beauty3Right = "beauty-type-module--beauty-3-right--EIxuE";
export var beauty4 = "beauty-type-module--beauty-4--N2Yo0";
export var beauty4Right = "beauty-type-module--beauty-4-right--hTuYZ";
export var beauty5 = "beauty-type-module--beauty-5--hhcoC";
export var beauty6 = "beauty-type-module--beauty-6--ZgCxi";
export var beauty7 = "beauty-type-module--beauty-7--2jDwi";
export var beauty8 = "beauty-type-module--beauty-8--as2wo";
export var beauty9 = "beauty-type-module--beauty-9--GSNoy";
export var beautyAboutSubtext = "beauty-type-module--beauty-about-subtext--pezF4";
export var beautyAboutText = "beauty-type-module--beauty-about-text--dpYZX";
export var beautyContainer = "beauty-type-module--beauty-container---relo";
export var beautyContent = "beauty-type-module--beauty-content--x1kYw";
export var beautyIconMiddle = "beauty-type-module--beauty-icon-middle--3WVan";
export var beautyImg = "beauty-type-module--beauty-img--bjKHh";
export var beautyIntro = "beauty-type-module--beauty-intro--Wbip1";
export var beautyLast = "beauty-type-module--beauty-last--WJ0yx";
export var beautyPage = "beauty-type-module--beauty-page--6yRe3";
export var beautyQuotation = "beauty-type-module--beauty-quotation--XWobB";
export var beautyTest = "beauty-type-module--beauty-test--cS+rW";
export var beautyTextWrapper = "beauty-type-module--beauty-text-wrapper--BxF5H";
export var beautyTitle = "beauty-type-module--beauty-title--FNRhx";
export var beautyWrapper = "beauty-type-module--beauty-wrapper--CCpZz";
export var clueAttention = "beauty-type-module--clue-attention--DFHnQ";
export var clueContent = "beauty-type-module--clue-content--dApU7";
export var clueHeading = "beauty-type-module--clue-heading--CrFy6";
export var clueIcon = "beauty-type-module--clue-icon--NDOvP";
export var clueWrapper = "beauty-type-module--clue-wrapper--PLpx4";
export var heading = "beauty-type-module--heading--+ex9+";
export var icon = "beauty-type-module--icon--tRgtq";
export var mainSection = "beauty-type-module--main-section--Mr-a-";
export var quoteSignature = "beauty-type-module--quote-signature--wkOvA";
export var quoteText = "beauty-type-module--quote-text--xAb9X";
export var ratio = "beauty-type-module--ratio--zwwP0";
export var testContent = "beauty-type-module--test-content---J-rW";
export var testIcon = "beauty-type-module--test-icon--5fXDC";
export var testMethod = "beauty-type-module--test-method--HWjPO";
export var testWrapper = "beauty-type-module--test-wrapper--O+rqn";