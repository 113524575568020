// extracted by mini-css-extract-plugin
export var backgroundImage = "color-type-section-module--background-image--pcjPI";
export var button = "color-type-section-module--button--tlUiO";
export var buttonNew = "color-type-section-module--button-new--jrE9M";
export var content = "color-type-section-module--content--BfHZ4";
export var contentFlexLeft = "color-type-section-module--content-flex-left--ejWVg";
export var contentFlexLeft2 = "color-type-section-module--content-flex-left2--+nKJf";
export var contentFlexRight = "color-type-section-module--content-flex-right--KDmE2";
export var contentFlexRight2 = "color-type-section-module--content-flex-right2---1NBj";
export var contentRight = "color-type-section-module--content-right---FiQW";
export var contentText = "color-type-section-module--content-text--fR59j";
export var contentTextLeft = "color-type-section-module--content-text-left--F9KKj";
export var contentWrapper = "color-type-section-module--content-wrapper--QBvX1";
export var hidden = "color-type-section-module--hidden--SxuFB";
export var main = "color-type-section-module--main--1eJtq";
export var secondWrapper = "color-type-section-module--second-wrapper--nXZkM";
export var subtitle = "color-type-section-module--subtitle--z+oDA";
export var title = "color-type-section-module--title--L6oJN";
export var titleContainer = "color-type-section-module--title-container--FKgjB";
export var topPadding = "color-type-section-module--top-padding--54p5O";
export var visible = "color-type-section-module--visible--2nWva";