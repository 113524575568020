import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    icon,
    title,
    text,
    topPadding,
    wrapperText,
    leftWrapper,
    leftWrapperIcon,
    leftWrapperText,
    rightWrapper,
    rightWrapperIcon,
    rightWrapperText,
} from './title-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface TitleSectionProps {
    withTopPadding?: boolean;
    image1?: React.ReactNode;
    title1?: string;
    text1?: string;
    image2?: React.ReactNode;
    title2?: string;
    text2?: string;
    className?: string;
}

export default function TitleSection({ withTopPadding, text1, className }: TitleSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${className} ${withTopPadding ? topPadding : ''}`}
        >
            <h2 className={text}>
                {text1}
            </h2>

        </SectionWrapper >
    );
}
