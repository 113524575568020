import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import WarningIcon from "../../assets/images/svg/warning2.svg";
import AttentionIcon from "../../assets/images/svg/attention2.svg";
import FairIcon from "../../assets/images/svg/fair_1.svg";
import PartyHatIcon from "../../assets/images/svg/party-hat.svg";
import RubyIcon from "../../assets/images/svg/ruby_1.svg";

import {
    beautyIntro,
    beautyPage,
    mainSection,
    beauty1,
    beauty1Right,
    beauty2,
    beauty3,
    beauty3Right,
    beauty4,
    beauty4Right,
    beauty5,
    beauty6,
    beauty7,
    beauty8,
    beauty9,
    beautyLast,
    beautyTextWrapper,
    beautyIconMiddle,
    icon,
    heading,
    aboutBeauties,
    beautyAboutText,
    beautyAboutSubtext,
    beautyImg,
    beautyQuotation,
    quoteText,
    quoteSignature,
    beautyContainer,
    beautyWrapper,
    ratio,
    beautyTitle,
    beautyContent,
    clueWrapper,
    clueAttention,
    clueIcon,
    clueHeading,
    clueContent,
    beautyTest,
    testWrapper,
    testMethod,
    testIcon,
    testContent,
} from "./beauty-type.module.scss";
import Typ from "../../assets/images/svg/typ-urody.svg";
import Clue from "../../assets/images/svg/clue.svg";
import Clothes from "../../assets/images/svg/clothes-hanger.svg";
import Light from "../../assets/images/svg/light-bulb.svg";
import Clues from "../../assets/images/svg/clues.svg";
import Diamond from "../../assets/images/svg/diamond-ring.svg";
import Warning from "../../assets/images/svg/warning.svg";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import usePageMetadata from "../../hooks/use-page-metadata";
import getTranslationKey from "../../utils/get-translation-key";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import RatioImage from "../atoms/ratio-image";
import BeautyTypeSection from "../organisms/beauty-type-section";
import FooterStylizeSection from "../molecules/footer-stylize-section";
import MainSection from "../organisms/sections/common/main-section";
import TwoBoxSection from "../organisms/sections/common/two-box-section";
import BackgroundBoxSection from "../organisms/sections/common/background-box-section";
import TwoTextSection from "../organisms/sections/common/two-text-section";
import TitleSection from "../organisms/sections/common/title-section";
import ColorTypeSection from "../organisms/sections/beauty-type/color-type-section";
import BackgroundTextSection from "../organisms/sections/common/background-text-section";
import DescriptionSection from "../organisms/sections/common/description-section";

interface IBeautyProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
}

const BeautyType: React.FC<IBeautyProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { site, mainImg, cold, warm, spring, summer, autumn, winter } = data;
    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);

    return (
        <MainLayout
            className={beautyPage}
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <MainSection showButton={true}
                titleText={<div>Typ urody<br />Znajdź najlepszy kolor dla siebie.</div>}
                text1="Każdy ma swoje ulubione kolory, które chętniej nosi, ale czy idą one w parze z naszym typem urody? Czy kolory, które tak uwielbiamy nosić, nie sprawiają, że np. wyglądamy na zmęczone? A może pogłębiają nasze cienie pod oczami? Zanim przejdziemy do analizy kolorystycznej, musimy określić najpierw jakim typem urody jesteśmy."
                className={mainSection}
            />
            <TwoBoxSection classNameLeftWrapper={beauty1}
                title={"Typ urody"}
                classNameRightWrapper={beauty1Right}
                text={<div>
                    <div>Określenie typu urody to pierwsza i najważniejsza czynność, którą należy wykonać podczas analizy kolorystycznej. Ustalenie, czy jesteś typem chłodnym, czy ciepłym pomaga sprecyzować paletę barw przyszłej garderoby oraz jej kolory neutralne.<br />
                        Typy urody przeważnie dzieli się na cztery pory roku: wiosna lato, jesień, zima, a je dalej dzieli się na podtypy:<br />

                        • Wiosna ciepła,<br />
                        • Wiosna jasna,<br />
                        • Wiosna czysta,<br />
                        • Lato chłodne,<br />
                        • Lato jasne,<br />
                        • Lato przygaszone,<br />
                        • Jesień ciepła,<br />
                        • Jesień ciemna,<br />
                        • Jesień przygaszona,<br />
                        • Zima chłodna,<br />
                        • Zima ciemna,<br />
                        • Zima czysta.<br /><br />
                    </div>
                    <div>
                        Każda z nas łączy w sobie co najmniej cechy tych dwóch podtypów.<br />
                        Na początku jednak uprościmy to do dwóch typów urody, od których wszystko się zaczyna.
                    </div>
                </div>}
                quoteText1={"\"Najlepszy kolor na świecie to taki, który dobrze na Tobie wygląda.\""}
                quoteSubtext1={"Coco Chanel"} />
            <BackgroundBoxSection className={beauty2} />
            <TwoBoxSection classNameLeftWrapper={beauty3}
                title={"Typ zimny"}
                classNameRightWrapper={beauty3Right}
                text={<div>
                    <div>• Twoja cera jest jasna lub bardzo jasna, o lekko różowym odcieniu.<br />
                        • Twoje znamiona kolorem zbliżają się do szarości.<br />
                        • Żyłki znajdujące się w okolicach mają niebieskawy odcień.<br />
                        • Twoje włosy są o lekko szarawym lub mysim odcieniu.<br />
                    </div>
                </div>} />
            <TwoBoxSection classNameLeftWrapper={beauty4}
                title={"Typ ciepły"}
                isReverse={true}
                classNameRightWrapper={beauty4Right}
                text={<div>
                    <div>• Twoja skóra może być jasna lub ciemna, ale wydaje się żółtawa.<br />
                        • Kolor znamion zbliża się do brązu.<br />
                        • Żyłki w okolicach dłoni mają kolor zielony.<br />
                        • Twoje włosy mają lekko złocisty lub jeśli są ciemne - czekoladowy odcień.<br />
                    </div>
                </div>}
                quoteText1={"\"Moda to sztuka, a Ty jesteś płótnem.\""}
                quoteSubtext1={"Anonimowy"} />
            <TitleSection text1="Zrób dla pewności test" />
            <TwoTextSection
                image1={FairIcon}
                title1="Metoda na neutrale"
                text1="Ta metoda polega na przymierzaniu ubrań w chłodnych i w ciepłych kolorach. Najlepiej ten test wypada przy używaniu do tego celu ubrań szarych i beżowych, czyli barw neutralnych. Jeśli lepiej Ci w beżach jesteś typem ciepłym. Jeśli zaś lepiej prezentujesz się w szarościach, masz chłodny typ urody."
                image2={RubyIcon}
                title2="Metoda na biżuterię"
                text2="Załóż biżuterię w kolorze złotym i srebrnym. Jeśli lepiej wyglądasz w złocie jesteś typem ciepłym. Natomiast w przypadku typu chłodnego lepiej Ci będzie w srebrze."
                classNameWrapper={beautyTextWrapper} />
            <TwoTextSection
                image1={PartyHatIcon}
                title1="Ciekawostka"
                text1="Nasza intuicja w kwestii, w kwestii intensywności kolorów idzie w parze z intensywnością urody (lub temperament) ale na ogół jest tak, że kobiety o jasnej cerze i delikatnych rysach wybierają barwy jasne, rozbielone, a kobiety o ciemniejsze skórze i włosach lepiej czują się w kolorach nasyconych."
                image2={AttentionIcon}
                title2="Wskazówka 1"
                text2="Musisz przykładać do twarzy, jak największą liczbę kolorów, by określić jakie kolory do Ciebie pasują. Budowanie garderoby wg jednej palety barw np. ciepłej lub zimnej znacznie ułatwia komponowanie zestawów. Nie musisz się martwić czy te rzeczy do siebie pasują."
                classNameWrapper={beautyTextWrapper}
                classNameIconWrapper={beautyIconMiddle} />
            <TwoTextSection
                image1={WarningIcon}
                title1="Uwaga"
                text1="Faktycznie źle dobrane kolory mogą nas „wymyć”, postarzyć czy spowodować wrażenie, jakbyśmy danego dnia się źle czuły. Ale to po prostu może być wina faktury danego materiału czy tego, w jaki sposób odbija on światło!"
                image2={AttentionIcon}
                title2="Wskazówka 2"
                text2="Jeśli jest kolor, który Ci się podoba, ale uznajesz go za nie twarzowy to po prostu „odsuń” go od twarzy i noś niżej – w formie spodni, spódnicy czy butów, torebki, paska."
                classNameWrapper={beautyTextWrapper} />
            <MainSection showButton={true}
                titleText={<div>Sprawdź jakim typem urody jesteś</div>}
                className={beauty5}
            />
            <ColorTypeSection className={beauty6}
                isRight={true}
                title="Wiosna"
                text={<div>
                    Skóra wiosny jest: delikatna, subtelna i wrażliwa. Pani wiosna często jest blada, choć szybko opala się na złoto-brązowy kolor. U niektórych kobiet występują także złociste piegi oraz widoczne przebarwienia. Jeśli wiosna, to zwykle jasny kolor włosów – blond, jasnorudy, złotawy, jasny brąz, a czasem nawet orzech. Oczy wiosny przeważnie są niebieskie, zielone, rzadziej piwne. Sam typ urody wiosna dzieli się na kolejne podtypy: czystą wiosnę, jasną wiosnę i ciepłą wiosnę.
                    <br /><br />
                    <b>Twoje kolory</b><br />
                    Pani Wiośnie pasują jasne barwy i delikatne pastele: beż, kremowy, jasny róż, jasny odcień niebieskiego, jasny odcień zieleni. Żywe i czyste tony zieleni są stworzone dla Wiosny, natomiast powinna unikać: ciemnych i zimnych kolorów takich jak czerń, brąz, zimny granat, gołębi, stalowa szarość. W garderobie Wiosny powinna przeważać jasna
                    i ciepła tonacja. Kremowy, łososiowy, écru i beże – wszystko z domieszką żółtego. Jeśli brąz, to od jasnego po średni, ciemny może być równie przytłaczający co czerń
                    i odwracać uwagę od twarzy. Kontrastów i zbyt mocnych wzory nie są dla tego typu urody.
                    <br /><br />
                    <b>Gwiazdy o typie urody wiosna</b><br />
                    Nicole Kidman, Kate Hudson, Gwyneth Paltrow, Christina Hendricks, Sophia Loren.
                </div>} />
            <ColorTypeSection className={beauty7}
                isRight={false}
                title="Lato"
                text={<div>
                    Typowo słowiański typ urody. Letni typ urody wbrew pozorom posiada zimną, nie ciepłą karnacją. Charakteryzuje ją: stonowana cera i kolor włosów, które przypomina „mysi odcień”. Zaliczają się do niego wszystkie odcienie blondu: od bardzo jasnego, aż do ciemnych. Cera może być w „mlecznym” odcieniu. Lato opala się na czerwono, ale szybko nabiera oliwkowej barwy. Wiele kobiet w Polsce to lato (jasne lub przygaszone). Ten typ urody charakteryzują jasne oczy z domieszką szarej barwy w kolorach od niebieskich, przez zielone aż po piwne. Ogólna uroda pani lato jest jednak jasna, chłodna i zgaszona.
                    <br /><br />
                    <b>Twoje kolory</b><br />
                    Lato pięknie wygląda w chłodnych pastelach: różu, niebieskim, zielonym, fioletowym. Pasuje także: popielaty, złamana biel, chłodna czerwień. Kobiety o tym typie urody źle natomiast wyglądają w kontrastowych barwach typu neonowe i pomarańczowe.
                    Nie pasują im też głęboka czerń, intensywna fuksja i ciemny brąz. Kolory ubrań, zwłaszcza tych, które znajdują się blisko twarzy, powinny być w odcieniach, w których widoczna jest kropla niebieskiego, fioletu lub różu. Lekko ciemne zielenie, chłodne turkusy i delikatnie szmaragdowe odcienie będą stanowiły również, dobrą bazę
                    w garderobie, którą powinien skompletować typ urody lato.
                    <br /><br />
                    <b>Gwiazdy o typie urody lato</b><br />
                    Reese Whiterspoon, Jennifer Aniston, Natalia Vodianova, Joanna Kulig, Cameron Diaz, Sarah Jessica Parker, Naomi Wats, Magdalena Cielecka, a także polska topmodelka Ania Jagodzińska.
                    <br /><br />
                </div>} />
            <ColorTypeSection className={beauty8}
                isRight={true}
                title="Jesień"
                text={<div>
                    Ciepły i najbardziej charakterystyczny typ urody. Cera pani jesieni jest najczęściej bardzo jasna, a czasem brzoskwiniowa lub śniada. Charakterystyczne dla tego typu urody są piegi w ciepłym intensywnym rudym lub złocisto-beżowym kolorze. Często występują nie tylko na twarzy, ale i na całym ciele. Jesień często ulega poparzeniom słonecznym. Natomiast, jeśli chodzi o włosy charakterystycznym kolorem są wszystkie odcienie rudego. Nawet jeśli posiadają inny kolor włosów, zawsze posiadają złote refleksy. Oczy jesiennego typu urody najczęściej wpadają w zielenie, szarości, brązy czy piwne barwy. Pani Jesień występuje w Polsce najrzadziej. W jesiennym typie urody specjaliści wyróżniają trzy podtypy: ciepła (prawdziwa jesień), przydymiona (zgaszona) jesień i głęboka (intensywna) jesień.
                    <br /><br />
                    <b>Twoje kolory</b><br />
                    Jesieni zdecydowanie pasują wszystkie odcieni brązów, zieleni czy kremu. Świetnie będzie prezentować się również w oliwkowym, khaki czy złocie. Kobiety o tym typie urody powinny za to unikać różu i chłodnych pasteli, przy których znikną. Czerwona odzież
                    w kolorze pomidora, raka i pelargonii podkreśli urodę. Warto by polubiła nosić ubrania pomarańczowe. Kobieta o urodzie jesień będzie najlepiej prezentowała się w kolorach przypominających jesienny krajobraz.
                    <br /><br />
                    <b>Gwiazdy o typie urody jesień</b><br />
                    Amy Adams, Cynthia Nixon, Emma Stone, Magdalena Walach, Eva Mendes, Julia Roberts, Natalie Portman, Lindsay Lohan, Julianne Moore.
                    <br /><br />
                </div>} />
            <ColorTypeSection className={beauty9}
                isRight={false}
                title="Zima"
                text={<div>
                    Pani Zima to kobieta o skórze w chłodnej tonacji i z ciemnymi, kontrastującymi z cerą włosami. Kojarzy się z Królewną Śnieżką. Jasna karnacja nie jest jednak regułą – zimowa uroda to też skóra o kolorze oliwkowym lub nawet śniadym, która w połączeniu
                    z brązowymi lub czarnymi włosami, które często są mocne i grube. Pani Zima przypomina czasem urodę latynoską. Oczy zimy są brązowe, piwne, błękitne, granatowe lub nawet czarne. Bez względu na barwę, tęczówki wyraźnie kontrastują z białym gałkami ocznymi. Oprawa oczu jest ciemna. Usta natomiast, przeważnie przyjmują czerwonawy lub delikatny, bladoróżowy odcień. Panie Zimy dzielą się na trzy podtypy: czyste, chłodne lub głębokie.
                    <br /><br />
                    <b>Twoje kolory</b><br />
                    Pani Zima powinna wybierać ubrania o mocnych kolorach. Najlepiej wygląda w czerni, granacie, śliwce i innych intensywnych kolorach takich jak np. bordo. Piękny kolor
                    dla ciebie to butelkowa zieleń. Dobrze będziesz wyglądać w intensywnych, chłodnych kolorach jak fuksja czy kobalt. Biała bluzka? Wyłącznie w śnieżnobiałym odcieniu, zapomnij o kości słoniowej czy ecru. Jeśli brąz, to odcień gorzkiej czekolady, który podkreśli Twoją urodę. Zimy uwielbiają zdecydowane, głębokie barwy oraz kontrastowe zestawienia.
                    <br /><br />
                    <b>Gwiazdy o typie urody zima</b><br />
                    Katherina Zeta-Jones, Salma Hayek i Monica Bellucci, Liv Tyler, Nigella Lawson, Courteney Cox.
                    <br /><br />
                </div>} />
            <DescriptionSection className={beautyLast}></DescriptionSection>
        </MainLayout>
    );
};

export const query = graphql`
            query($language: String!) {
                locales: allLocale(filter: {language: {eq: $language } }) {
                edges {
                node {
                ns
                    data
            language
                }
            }
        }
            mainImg: file(relativePath: {eq: "beauties.jpg" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            cold: file(relativePath: {eq: "zimny.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            warm: file(relativePath: {eq: "cieply.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            spring: file(relativePath: {eq: "wiosna.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            summer: file(relativePath: {eq: "lato.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            autumn: file(relativePath: {eq: "jesien.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            winter: file(relativePath: {eq: "zima.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            logo: file(relativePath: {eq: "emilka.png" }) {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
            site {
                ...siteFields
            }
    }
            `;

export default BeautyType;
