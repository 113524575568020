// extracted by mini-css-extract-plugin
export var backgroundImage = "title-section-module--background-image--QfA7a";
export var hidden = "title-section-module--hidden--5+Xoj";
export var icon = "title-section-module--icon--eUaE1";
export var main = "title-section-module--main---oDzb";
export var subtitle = "title-section-module--subtitle--3rw4+";
export var text = "title-section-module--text--rU4wV";
export var title = "title-section-module--title--u27LQ";
export var titleContainer = "title-section-module--title-container--Za3za";
export var topPadding = "title-section-module--top-padding--GtHR9";
export var visible = "title-section-module--visible--Piw80";
export var wrapperText = "title-section-module--wrapper-text--fcZLf";